html .mat-mdc-slide-toggle {
  --mdc-switch-disabled-unselected-handle-color: var(--calenso-white);
  --mdc-switch-disabled-unselected-track-color: #00000061;
  --mdc-switch-unselected-focus-state-layer-color: var(--calenso-white);
  --mdc-switch-unselected-pressed-state-layer-color: var(--calenso-white);
  --mdc-switch-unselected-hover-state-layer-color: var(--calenso-white);
  --mdc-switch-unselected-focus-track-color: #00000061;
  --mdc-switch-unselected-hover-track-color: #00000061;
  --mdc-switch-unselected-pressed-track-color: #00000061;
  --mdc-switch-unselected-track-color: #00000061;
  --mdc-switch-unselected-focus-handle-color: var(--calenso-white);
  --mdc-switch-unselected-hover-handle-color: var(--calenso-white);
  --mdc-switch-unselected-pressed-handle-color: var(--calenso-white);
  --mdc-switch-unselected-handle-color: var(--calenso-white);
  --mdc-switch-disabled-unselected-icon-color: var(--calenso-white);
  --mdc-switch-unselected-icon-color: var(--calenso-white);
  --mdc-switch-handle-surface-color: transparent;

  &.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-handle-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-hover-state-layer-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-pressed-state-layer-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-focus-handle-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-hover-handle-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-pressed-handle-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-focus-track-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-hover-track-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-pressed-track-color: var(--calenso-slide-toggle-green-color);
    --mdc-switch-selected-track-color: var(--calenso-slide-toggle-green-color);
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch__ripple {
    display: none;
  }

  .mdc-switch__handle {
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  }

  label {
    font-family: "Open Sans", sans-serif !important;
    color: var(--calenso-font-color);
  }
}
