$theme-color-interval: 8% !default;

// Position
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

// Toast
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;

  .ngx-toastr {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03) !important;

    .toast-title {
      font-weight: bold;
    }

    .toast-message {
      word-wrap: break-word;
      padding-right: 10px;
    }

    .toast-close-button {
      position: absolute;
      right: 0;
      bottom: -4px;
      width: 24px;
      font-size: 22px !important;
      font-weight: 500;
      opacity: 1;

      span {
        opacity: 0.5;
        color: var(--calenso-white);
        cursor: pointer;
      }

      &:hover,
      :focus {
        span {
          opacity: 1;
        }
      }
    }

  }

  /* Success toast styles */
  .toast-success {
    background-color: var(--calenso-green) !important;
    background-image: url('/assets/images/check_circle.svg');

    .toast-close-button {
      background-color: var(--calenso-success-overlay-toast);
    }
  }

  /* Error toast styles */
  .toast-error {
    background-color: var(--calenso-red) !important;
    background-image: url('/assets/images/cancel.svg');

    .toast-close-button {
      background-color: var(--calenso-error-overlay-toast);
    }
}
}

// Responsive Design
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}