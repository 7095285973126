// Header
.calio-header-bar {
  padding: 0px;
  background-color: var(--calenso-white);

  -webkit-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;

  .calio-header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      width: 100px;
    }
  }

  .calio-header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .icon {
    color: var(--calenso-font-color);
  }

  sup {
    top: -0.9em;
    right: 4px;
  }

  .notification-box-title {
    font-size: 18px;
  }

  .notification-box-title-count {
    font-size: 18px;
    color: var(--calenso-red);
  }

  .notification-load-all {
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 22px;
    font-weight: 400;
  }

  .load-more-link {
    color: var(--calenso-font-color);
  }

  .load-more-link:hover {
    text-decoration: none !important;
  }

  .notification-link {
    cursor: pointer;
  }

  .language-select {
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .custom-dashboard-menu {
    top: 8px !important;
    min-width: unset;
    left: 0 !important;
  }

  .dropdown-item {
    padding: 6px 12px;
    font-size: 13px !important;
  }

  .calenso-add-btn {
    cursor: pointer;
    margin-bottom: 5px;
  }

  .release-notes {
    color: var(--calenso-font-color);
    font-size: 13px;
    font-weight: 600;
  }

  .divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .navbar-toggler {
    cursor: pointer;
  }

  #userProfile {
    cursor: pointer;
  }
}

@media (min-width: 1100px) {
  .notification-menu {
    min-width: 20rem !important;
    top: 35px !important;
    left: -60px !important;
    max-height: 420px !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 1100px) {
  .calio-header-bar {
    .calio-header-left {
      .logo {
        width: 75px;
      }
    }
  }

  .notification-menu {
    min-width: 16rem !important;
    top: 35px !important;
    left: -120px !important;
    max-height: 294px !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 370px) {
  .calio-header-bar {
    .calio-header-left {
      .logo {
        display: none;
      }
    }
  }
}

// Horizontal navbar
.navbar {
  padding: 0px;
  background-color: var(--calenso-primary-color);
  min-height: 53px;

  &.calio-new-navbar {
    background-color: var(--calenso-white);
  }
}

.dropdown-title {
  color: var(--calenso-white);
  text-decoration: none;
}

.dropdown-menu {
  background-color: var(--calenso-primary-color);
  //top: 50px !important;
  border-top: none;
  min-width: 12rem;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0px !important;
}

.dropdown-item {
  color: var(--calenso-white) !important;
  padding: 6px 30px 6px 30px;
}

.dropdown-item:hover {
  color: var(--calenso-font-color) !important;
  background-color: #f6f7fa;
}

.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.chevron-down-icon {
  font-size: 12px;
  opacity: 0.5;
}

// Mobile Header
.calio-mobile-navigation {
  position: absolute;
  width: 100%;
  padding: 0;

  .navbar-collapse {
    background-color: var(--calenso-primary-color);
    z-index: 999;
    padding-top: 15px;
    padding-bottom: 15px;

    ul {

      .dropdown {
        padding: 0.5rem 1rem;
      }

      li {

        a {
          text-align: center;
          color: white !important;
          font-size: 16px;
          font-weight: 600;
        }

        .dropdown-menu {

          background-color: transparent;
          box-shadow: none !important;

          a {
            color: white !important;
          }

          a.dropdown-item {
            font-weight: lighter;
          }

          .dropdown-item:hover,
          .dropdown-item:focus {
            color: white;
            text-decoration: none;
            background-color: transparent;
          }

        }
      }
    }
  }

  hr {
    border: 0;
    clear: both;
    display: block;
    width: 70%;
    background-color: rgba(255, 255, 255, 0.8);
    height: 1px;
  }
}


// Header notification box
.header-notification-box {
  .notification-icon {
    img {
      width: 26px;
    }
  }

  .notification-icon-detail {
    color: var(--calenso-gray);
    font-size: 12px;
  }

  .notification-title {
    font-size: 14px;
    font-weight: 600;
  }

  .notification-description {
    font-size: 12px;
    letter-spacing: .2px;
    line-height: 22px;
    font-weight: 400;
  }

  .text-problem {
    color: var(--calenso-red);
    width: 90%;
    overflow: hidden;
  }

  .text-announcement {
    color: var(--calenso-green);
  }

  .text-warning {
    color: var(--calenso-yellow);
  }

  .text-information {
    color: var(--calenso-font-color);
  }
}

