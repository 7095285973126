body {
  --mdc-typography-body2-letter-spacing: normal;
  --mdc-typography-font-family: "Open Sans", sans-serif !important;

  --mdc-typography-body1-font-family: "Open Sans", sans-serif !important;
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-body1-letter-spacing: normal;

  --mdc-icon-button-icon-size: 17px;

  --mdc-theme-text-primary-on-background: var(--calenso-primary-color);
  --mat-table-row-item-label-text-font: "Open Sans", sans-serif;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-header-headline-font: "Open Sans", sans-serif;
  --mat-stepper-header-label-text-font: "Open Sans", sans-serif;
  --mdc-filled-text-field-label-text-font: "Open Sans", sans-serif;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-option-label-text-size: 14px;
  --mat-form-field-container-text-font: "Open Sans", sans-serif;
  --mat-form-field-container-text-tracking: normal;
  --mdc-filled-text-field-disabled-container-color:#FFF;
  --mat-form-field-container-height:35px;
}
