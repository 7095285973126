.calendar-header-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--calenso-font-color);
}

.week-days {
  border: 1px solid lightgray;
}

.custom-calendar-header {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}

.disabled-day {
  cursor: auto !important;
  color: #e1e1e1 !important;
}

.weekend-day {
  cursor: pointer !important;
  color: var(--calenso-font-color) !important;
}

::ng-deep {
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: var(--calenso-font-color);
  }
}
