
.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 20px;

  .mdc-checkbox__background {
    height: 16px;
    width: 16px;
  }

  &.mat-accent {
    --mdc-checkbox-selected-checkmark-color: var(--calenso-white);
    --mdc-checkbox-selected-focus-icon-color: var(--calenso-primary-color);
    --mdc-checkbox-selected-hover-icon-color: var(--calenso-primary-color);
    --mdc-checkbox-selected-icon-color: var(--calenso-primary-color);
    --mdc-checkbox-selected-pressed-icon-color: var(--calenso-primary-color);
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  }

  .mdc-form-field {
    white-space: inherit;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  label {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400;
    color: var(--calenso-font-color);
  }

  .mdc-checkbox__ripple, .mat-mdc-checkbox-ripple {
    display: none;
  }
}
