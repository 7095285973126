.breadcrumb-main {
  background-color: var(--calenso-white);
}

.new-theme {
  .breadcrumb-main {
    margin: 15px 0;
  
    .breadcrumb {
      padding: 12px 0 !important;
    }
  }
}

.old-theme {
  .breadcrumb-main {
    margin-top: 30px;
    margin-bottom: 40px;
    border-radius: var(--calenso-border-radius);
    
    .breadcrumb {
      padding: 0.75rem 1rem !important;
    }
  }
}
