@import '_responsive.scss';

:root {
  --calenso-white: #FFFFFF;
  --calenso-white-smock: #F5F5F5;
  --calenso-light: #F8F9FA;
  --calenso-black: #000000;
  --calenso-black-shade-1: #2D333A;
  --calenso-black-shade-2: rgba(45, 51, 58, 0.5);
  --calenso-gray: #808080;
  --calenso-light-gray: #DDDDDD;
  --calenso-light-gray-shade-1: #F7F7F7;
  --calenso-light-gray-shade-2: #D3D3D3;
  --calenso-green: #97CC2E;
  --calenso-light-green: #C7EA71;
  --calenso-light-green-shade-2: #F2FFD7;
  --calenso-red: #EF6565;
  --calenso-light-red: #FFCBCB;
  --calenso-violet: #7676FF;
  --calenso-violet-hover: #7B7BFA;
  --calenso-light-violet: #B1B2FD;
  --calenso-sky-blue: #68D6F0;
  --calenso-light-sky-blue: #EBF1FB;
  --calenso-light-blue: #F4F6FA;
  --calenso-dark-yellow: #856404;
  --calenso-yellow: #FFCB3D;
  --calenso-light-yellow: #FFFFE0;
  --calenso-orange: #FFA500;
  --calenso-light-orange: #FCDEA2;
  --calenso-hawkes-blue: #DFE1E9;
  --calenso-blueberry: #5555FF;
  --calenso-blueberry-light: #5555FF0F;
  --calenso-border-color: #CED4DA;
  --calenso-border-light-color: #CED4DA52;

  --calenso-primary-color: #3A4A66;
  --calenso-background-color: #A9B9D4;
  --calenso-slide-toggle-green-color: var(--calenso-green);

  --calenso-badge-success-bg: #DDFFDD;
  --calenso-badge-success: #4CAF50;
  --calenso-badge-warning-bg: #FDF6E0;
  --calenso-badge-warning: #F5B800;
  --calenso-badge-dark-warning: #ffa754;
  --calenso-badge-danger-bg: #FFDDDD;
  --calenso-badge-danger: #F44336;
  --calenso-badge-info-bg: #DDFFFF;
  --calenso-badge-info: #2196F3;
  --calenso-badge-info-integration: #E2EEFF;

  // New calenso colors
  --calenso-ghost-white: #F6F6FF;
  --calenso-hawkes-blue: #D7DDF8;
  --calenso-font-color: var(--calenso-black);

  --calenso-integration-card: #334155;
  --calenso-integration-badge-text-color: #779EDA;
  --calenso-integration-detail: #AC82FD;
  --calenso-integration-detail-background: #F7F2FF;

  // Toaster
  --calenso-success-toast: var(--calenso-green);
  --calenso-success-overlay-toast: #8abb2a;
  --calenso-error-toast: var(--calenso-red);
  --calenso-error-overlay-toast: var(--calenso-badge-danger);

  // border
  --calenso-border-radius: 8px;
  --calenso-border: #ccc;

  //Header height
  --header-height: 0px;
}
