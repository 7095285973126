.tooltip {
  --bs-tooltip-bg: var(--calenso-primary-color) !important;

  .tooltip-inner {
    padding: 2px 5px;
    color: var(--calenso-white);
    border-radius: 0.2rem;
    text-align: center;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  opacity: 1 !important;

}
