@mixin breakpoint($class) {

  @if $class == xxs {
    @media (max-width: 640px) {
      @content;
    }
  }

  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }
}