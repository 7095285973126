//@use '@angular/material' as mat;
@import "./app/shared/scss/_variable.scss";
@import "./styles/main";
@import "bootstrap/scss/bootstrap";
@import "./styles/toastr-bs5-alert.scss";
@import "./app/shared/scss/_responsive.scss";
@import "~@bryntum/calendar/calendar.stockholm.css";
@import "./styles/material-overwrite/typography.scss";
@import "./styles/material-overwrite/slide-toggle.scss";
@import "./styles/material-overwrite/checkbox.scss";
@import "./styles/material-overwrite/radio.scss";
@import "./styles/material-overwrite/form-field.scss";
@import "./styles/material-overwrite/dialog.scss";

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-300.woff) format("woff"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-300.woff2) format("woff2"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-300.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-400.eot);
  src: local("Open Sans"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-400.woff) format("woff"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-400.woff2) format("woff2"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-400.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-400.svg#OpenSans) format("svg"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-400.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-600.woff) format("woff"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-600.woff2) format("woff2"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-600.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-700.woff) format("woff"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-700.woff2) format("woff2"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-700.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(assets/fonts/Open_Sans/Open-Sans-normal-800.woff) format("woff"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-800.woff2) format("woff2"),
    url(assets/fonts/Open_Sans/Open-Sans-normal-800.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Material_Icons/Material-Icons-normal-400.eot);
  src: local("Material Icons"),
    url(assets/fonts/Material_Icons/Material-Icons-normal-400.woff) format("woff"),
    url(assets/fonts/Material_Icons/Material-Icons-normal-400.ttf) format("truetype"),
    url(assets/fonts/Material_Icons/Material-Icons-normal-400.woff2) format("woff2"),
    url(assets/fonts/Material_Icons/Material-Icons-normal-400.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/Material_Icons/Material-Icons-normal-400.svg#MaterialIcons) format("svg");
  font-display: swap;
}

// html,
body {
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  color: var(--calenso-font-color);
  font-size: 14px !important;
}

.file-upload-button {
  font-size: 14px;
  cursor: pointer;
}

.ng-placeholder {
  top: 7px !important;
}

.dropdown-item {
  color: var(--calenso-font-color) !important;
  text-decoration: none;
}

.cal-month-view .cal-cell-top {
  min-height: 60px !important;
}

.list-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.ng-placeholder {
  font-size: 14px;
}

.alert {
  border-radius: var(--calenso-border-radius);
}

.alert-info {
  background-color: var(--calenso-font-color);
}

.iti {
  // display: block !important;
  width: 100%;
}

.iti .iti__country-list {
  position: relative !important;
  border: none !important;
  box-shadow: none !important;
}

.iti .iti__country-list .iti__flag-box,
.iti .iti__country-list .iti__country-name {
  font-size: 14px !important;
}

.iti .iti__country-list .iti__country .iti__dial-code {
  font-size: 14px !important;
}

.iti.separate-dial-code .selected-dial-code {
  font-size: 14px !important;
}

.iti__country-container {
  .iti__selected-country {
    background-color: #0000000d;

    .iti__selected-dial-code {
      margin: 0 6px 0 0;
    }
  }
}

.iti {
  #country-search-box {
    padding-left: 6px !important;
    height: 40px !important;
    font-size: 14px !important;
  }
}

.toast-error {
  background-color: var(--calenso-red) !important;
}

.toast-success {
  background-color: #97cc2e !important;
}

button:focus {
  outline: 0px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: var(--calenso-white);
  background-color: var(--calenso-font-color);
  border-color: var(--calenso-font-color);
}

:focus {
  outline: 0px;
}

.toast-container .toast {
  box-shadow: 0 0 4px #999999 !important;
}

.toast-container .toast:hover {
  box-shadow: none !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  font-family: "Open Sans", sans-serif !important;
}

button {
  font-size: 14px !important;
}

button:focus {
  box-shadow: none !important;
}

.form-control {
  font-size: 14px !important;
}

.form-control:focus {
  border-color: var(--calenso-border) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

label {
  font-size: 14px;
  font-weight: 600;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container {
  .ng-value-container {
    .ng-value {
      font-size: 14px;
      line-height: 1.5;
      color: #495057;
    }

    .ng-placeholder {
      font-size: 14px;
      line-height: 1.5;
      color: #6d757c;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 14px !important;
  background-color: #f5faff !important;
  border-radius: 2px !important;
  /* border: 1px solid #c2e0ff !important; */
  padding: 0px 6px 0px 0px !important;
  /* white-space: nowrap !important; */
  border: 0.5px solid #c2e0ff !important;
  display: flex;

  .ng-value-label {
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
  }
}


#toast-container>div {
  opacity: 1;
}

a {
  font-size: 14px;
  color: #495057;
}

.mat-mdc-row {
  vertical-align: top;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.cal-month-view .cal-cell-row:hover {
  background-color: transparent !important;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: transparent !important;
}

.cal-month-view .cal-header .cal-cell {
  font-weight: 500;
}

.cal-month-view .cal-days {
  border-color: transparent !important;
}

.cal-month-view .cal-days .cal-cell-row {
  border-color: transparent !important;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-color: transparent !important;
}

.cal-month-view .cal-cell {
  align-items: center !important;
}

.cal-month-view .cal-day-number {
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  margin-top: 15px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 8px;
}

.cancel-button {
  margin-right: 4px;
  height: 40px;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: transparent !important;
}

.cal-days {
  margin-top: 15px !important;
}

.cal-month-view .cal-day-cell {
  min-height: 0px !important;
}

.cal-month-view {
  padding-top: 20px !important;
}

.cal-disabled {
  color: #e1e1e1 !important;
}

.cal-day-number {
  cursor: pointer !important;
}

.cal-selected {
  .cal-day-number {
    width: 42px;
    height: 42px;
    color: var(--calenso-white);
    background-color: var(--calenso-primary-color);
    border-radius: var(--calenso-border-radius);
  }
}

.cal-month-view .cal-cell-top {
  min-height: 60px;
}

.custom-select-field {
  .ng-dropdown-panel {
    border-left: 2px solid var(--calenso-border);
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: var(--calenso-red);
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.ng-select-focused {
  outline: 0px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: var(--calenso-border) !important;
  box-shadow: none !important;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.rocket-box .progressbar .progress {
  border-radius: var(--calenso-border-radius);
  border: 2px solid #79b0f8;
  background-color: var(--calenso-white);
  // border-image: linear-gradient(#7974f7, #77b1f8) 1 1 round;
}

.rocket-box .progressbar .limit-exceeded {
  border: 2px solid var(--calenso-red);
}

.rocket-box .progressbar .progress-bar {
  background-color: var(--calenso-violet);
  background-image: linear-gradient(to right, var(--calenso-violet), #77b1f8);
  font-size: 14px;
}

.rocket-box .progressbar .limit-exceeded .progress-bar {
  background-color: var(--calenso-red);
  background-image: linear-gradient(to right, var(--calenso-red), var(--calenso-red));
  color: var(--calenso-white);
  font-size: 14px;
}

.event-progressbar .progress {
  border-radius: var(--calenso-border-radius);
  border: 2px solid #79b0f8;
  background-color: var(--calenso-white);
  // border-image: linear-gradient(#7974f7, #77b1f8) 1 1 round;
}

.event-progressbar .progress-bar {
  background-color: var(--calenso-violet);
  background-image: linear-gradient(to right, var(--calenso-violet), #77b1f8);
  font-size: 14px;
}

.progress-bar-red .progress-bar {
  background-color: var(--calenso-yellow) !important;
  background-image: linear-gradient(to right,
      var(--calenso-yellow),
      var(--calenso-red)) !important;
  font-size: 14px !important;
}

.inner-html-box {
  a {
    text-decoration: none;
    color: #68d6f0;
  }
}

.customer-note-bottom-sheet {
  color: var(--calenso-font-color);
}

.customer-note-ng-select {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 150px;
  }
}

@media (min-width: 992px) {
  .sticky-header {
    .calio-header {
      width: 100%;
      position: absolute;
      box-shadow: none;
      -webkit-box-shadow: 0px 18px 56px -10px rgba(30, 30, 30, 0.2);
      -moz-box-shadow: 0px 18px 56px -10px rgba(30, 30, 30, 0.2);
      box-shadow: 0px 18px 56px -10px rgba(30, 30, 30, 0.2);

      -webkit-transition: all 0.5s ease;
      -moz-transition: position 10s;
      -ms-transition: position 10s;
      -o-transition: position 10s;
      transition: all 0.5s ease;

      &.sticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1000;
        animation: smoothScroll 1s both;
      }
    }
  }
}

@media (max-width: 991px) {
  .calio-body {
    margin-top: 0px !important;
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

.hover-card:hover {
  box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
}

.card {
  border-radius: var(--calenso-border-radius) !important;

  .divider {
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-body {
    padding-bottom: 20px !important;
  }
}

.skeleton-animation {
  position: relative;
  overflow: hidden;

  .bar {
    background-color: #e7e7e7;
    height: 14px;
    border-radius: var(--calenso-border-radius);
    width: 100%;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 80%);
    background-size: 200px 100%;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation-name: loading;
    animation-duration: 5s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    // animation: loading 5.05s infinite;
  }

  &.speed-1 {
    &:after {
      animation-duration: 1s;
    }
  }
}

@keyframes loading {
  40% {
    background-position: 100% 0;
  }

  100% {
    background-position: 120% 0;
  }
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .customer-note-bottom-sheet {
    min-width: 100vw !important;
  }
}

.calenso-widget-modal {
  .modal-dialog {
    min-height: 1200px !important;
  }
}

html.cdk-global-scrollblock {
  top: 0px !important;
  overflow: auto !important;
}

.card-separator {
  margin: 0px -10px 10px -10px !important;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
}

/* table and search */
.primary-title {
  font-size: 22px;
  letter-spacing: 0.2px;
  font-weight: 600;
}

.sub-title {
  font-size: 16px;
  color: var(--calenso-gray);
  letter-spacing: 0.3px;
}

.search-box-block {
  width: 30%;

  .search-box {
    input {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      height: 40px;
      box-shadow: 0 00.2rem 1rem rgba(0, 0, 0, 0.15) !important;
      border: none;
    }

    input:focus {
      box-shadow: 0 00.2rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .search-icon {
      background-color: var(--calenso-primary-color);
      color: var(--calenso-white);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(246, 247, 250, 1);
}

.table {
  color: var(--calenso-font-color);
  margin-top: 20px;

  thead {
    line-height: 30px;

    th {
      border-bottom: 0px;
      border-top: 0px;
    }
  }
}

.mat-mdc-table {
  width: 100%;
  color: var(--calenso-font-color);
  box-shadow: none;
  font-size: 14px;

  .table-edit-icon {
    display: flex;
    justify-content: flex-end;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(246, 247, 250, 1);
  }

  .mat-mdc-header-cell {
    color: var(--calenso-font-color);
    font-size: 13px !important;
    font-weight: 600 !important;
    border-bottom: none;
    min-width: 100px;
  }

  .mat-mdc-cell {
    color: var(--calenso-font-color);
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.white-button {
  color: var(--calenso-font-color) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-font-color) !important;

  &:hover {
    color: var(--calenso-font-color) !important;
    background-color: var(--calenso-white) !important;
    border-color: var(--calenso-font-color) !important;
  }
}

.save-button {
  color: var(--calenso-white) !important;
  background-color: var(--calenso-font-color) !important;
  border-color: var(--calenso-font-color) !important;

  &:hover {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-font-color) !important;
    border-color: var(--calenso-font-color) !important;
  }
}

.create-button {
  color: var(--calenso-white) !important;
  background-color: var(--calenso-font-color) !important;
  border-color: var(--calenso-font-color) !important;
  height: 40px !important;

  &:hover {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-font-color) !important;
    border-color: var(--calenso-font-color) !important;
  }
}

.yellow-button {
  color: var(--calenso-black) !important;
  background-color: var(--calenso-yellow) !important;
  border-color: var(--calenso-yellow) !important;
  height: 40px !important;

  &:hover {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-yellow) !important;
    border-color: var(--calenso-yellow) !important;
  }
}

.cancel-button {
  margin-left: 4px;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-red) !important;
  border-color: var(--calenso-red) !important;
}

.e-footer-content {
  display: none !important;
}

.dropdown-toggle::after {
  display: none;
}

a,
a:visited,
a:hover {
  color: var(--calenso-violet);
  text-decoration: none;
}

.custom-link,
.custom-link:visited,
.custom-link:hover,
.href-link,
.href-link:visited,
.href-link:hover {
  color: var(--calenso-font-color);
  text-decoration: none;
}

.event-progressbar .progres-bar-red .progress-bar {
  background-color: var(--calenso-red);
  background-image: linear-gradient(to right, var(--calenso-red), var(--calenso-red));
  font-size: 14px;
}

.event-progressbar .progres-bar-red .progress {
  border: 2px solid var(--calenso-red);
}

.dropdown-item {
  font-size: 14px;
  color: var(--calenso-font-color);
}

.calenso-add-btn {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.calenso-edit-btn {
  height: 18px;
  width: 19px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.event-participant-bottom-sheet {
  overflow-x: hidden !important;
}

.load-more-button,
.load-more-button:hover,
.load-more-button:active {
  color: var(--calenso-white) !important;
  background-color: var(--calenso-font-color) !important;
  border-color: var(--calenso-font-color) !important;
}

.modal {
  z-index: 10000;
}

.dropdown-item:hover,
.dropdown-item:focus {
  outline: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--calenso-font-color) !important;
  background-color: inherit;
}

.mat-mdc-table {
  font-family: "Open Sans", sans-serif !important;
}

.cal-default {
  background-color: var(--calenso-light-violet) !important;
  border-color: var(--calenso-violet) !important;
  color: var(--calenso-violet) !important;
}

.cal-default.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-violet) !important;
}

.cal-blue {
  background-color: var(--calenso-light-violet) !important;
  border-color: var(--calenso-violet) !important;
  color: var(--calenso-violet) !important;
}

.cal-blue.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-violet) !important;
}

.cal-violet {
  background-color: var(--calenso-light-violet) !important;
  border-color: var(--calenso-violet) !important;
  color: var(--calenso-violet) !important;
}

.cal-violet.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-violet) !important;
}

.cal-yellow {
  background-color: var(--calenso-light-yellow) !important;
  border-color: var(--calenso-yellow) !important;
  color: var(--calenso-yellow) !important;
}

.cal-yellow.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-yellow) !important;
}

.cal-orange {
  background-color: var(--calenso-light-orange) !important;
  border-color: var(--calenso-orange) !important;
  color: var(--calenso-orange) !important;
}

.cal-orange.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-orange) !important;
}

.cal-red {
  background-color: var(--calenso-light-red) !important;
  border-color: var(--calenso-red) !important;
  color: var(--calenso-red) !important;
}

.cal-red.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-red) !important;
}

.cal-green {
  background-color: var(--calenso-light-green) !important;
  border-color: var(--calenso-green) !important;
  color: var(--calenso-green) !important;
}

.cal-green.b-sch-event.b-sch-event-selected * {
  color: var(--calenso-green) !important;
}

.cal-vacation {
  background: repeating-linear-gradient(45deg,
      rgba(155, 155, 155, 0.5),
      rgba(155, 155, 155, 0.5) 8px,
      transparent 8px,
      transparent 16px) !important;
  color: #666 !important;
  background-color: transparent !important;
  border-color: var(--calenso-font-color) !important;

  &:hover,
  &:focus,
  &:active {
    background: repeating-linear-gradient(45deg,
        rgba(155, 155, 155, 0.5),
        rgba(155, 155, 155, 0.5) 8px,
        transparent 8px,
        transparent 16px) !important;
    color: #666 !important;
    background-color: transparent !important;
    border-color: var(--calenso-font-color) !important;
  }
}

.cal-vacation.b-sch-event.b-sch-event-selected * {
  color: #666 !important;
}

// External social icons

/* External Calendars */
.cal-google {
  background-color: var(--calenso-background-color) !important;
  border-color: var(--calenso-font-color) !important;
  color: var(--calenso-font-color) !important;
  //background-color: rgba(251, 188, 5, 0.3);
}

.cal-icloud {
  background-color: var(--calenso-background-color) !important;
  border-color: var(--calenso-font-color) !important;
  color: var(--calenso-font-color) !important;
  //background-color: rgba(109, 110, 113, 0.3);
}

.cal-office365 {
  background-color: var(--calenso-background-color) !important;
  border-color: var(--calenso-font-color) !important;
  color: var(--calenso-font-color) !important;
  //background-color: rgba(220, 55, 1, 0.3);
}

.cal-exchange {
  background-color: var(--calenso-background-color) !important;
  border-color: var(--calenso-font-color) !important;
  color: var(--calenso-font-color) !important;
  //background-color: rgba(0, 144, 198, 0.3) !important;
}

.cal-external {
  background-color: var(--calenso-background-color) !important;
  border-color: var(--calenso-font-color) !important;
  color: var(--calenso-font-color) !important;
}

// TOOLTIP
.b-sch-event.b-sch-event-wrap.b-sch-style-plain {
  border-right: 1px solid var(--calenso-white);
}

.b-sch-event .name,
.b-sch-event-selected .name {
  margin-left: 0.4em !important;
  width: 100% !important;
  font-weight: 600 !important;
  font-family: "Lato", sans-serif !important;
  padding-top: 2px;
}

.b-sch-event .customer-name,
.b-sch-event .services,
.b-sch-event-selected .customer-name,
.b-sch-event-selected .services {
  font-weight: 400 !important;
  font-family: "Lato", sans-serif !important;
}

.b-sch-event .time,
.b-sch-event-selected .time {
  text-align: right !important;
  font-weight: 600 !important;
}

.b-sch-event-tooltip {
  min-width: 190px;
}

.b-tooltip-content {
  background: var(--calenso-white) !important;
  color: var(--calenso-font-color);
}

.b-sch-clock-text {
  color: var(--calenso-font-color) !important;
}

button.b-button.b-raised.b-blue,
button.b-raised.b-button,
button.b-widget.b-button.b-raised {
  color: var(--calenso-white);
  border: none;
  background-color: var(--calenso-violet);
}

button.b-button.b-raised.b-blue,
button.b-raised.b-button,
button.b-widget.b-button.b-raised {
  color: var(--calenso-white);
  border: none;
  background-color: var(--calenso-violet) !important;
}

.b-sch-event-tooltip .b-tooltip-header {
  background-color: var(--calenso-violet) !important;
}

.b-blue.b-raised {
  background-color: var(--calenso-violet) !important;
}

header.scheduler-header {
  background-color: var(--calenso-font-color);
}

.b-sch-header-timeaxis-cell {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  color: #757575;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  overflow: hidden;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  font-size: 1em;
  font-weight: 600;
  padding: 0.5em 0 !important;
  white-space: nowrap;
  contain: strict;
  user-select: none;
}

.b-resourceheader-cell {
  font-weight: 600;
}

.b-sch-resource-time-range {
  font-size: 12px;
}

//horizontal scheduler
.b-scheduler {
  .b-sch-event {
    .image-container {
      display: none;
    }

    .time {
      display: none;
    }
  }
}

//vertical scheduler
.b-scheduler.b-sch-vertical {
  .b-sch-event {
    .image-container {
      display: initial;
    }

    .time {
      display: initial;
    }
  }
}

.b-cust-green {

  &:hover,
  &:focus {
    color: var(--calenso-white);
  }
}

.scheduler-header {
  color: var(--calenso-white);
  padding: 0 !important;
  display: initial;
  align-items: center;

  @include breakpoint(xxs) {
    height: 150px;
  }

  #tools {
    display: flex;
    align-items: center;
    background-color: inherit;
    transition: background-color 0s, margin-top 0.3s;
    padding-right: 1em;
    justify-content: flex-end;

    .datePickerContainer {
      margin: 0 !important;
    }

    .tools-element.date {
      min-width: 156px;

      .b-icon-calendar {
        color: var(--calenso-black);
      }
    }

    .tools-element {
      background: var(--calenso-white);
      border-radius: var(--calenso-border-radius);
      margin-top: 5px;
      margin-bottom: 5px;
      min-width: 250px;
      height: 40px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 8px;
      padding-right: 8px;

      .b-field-inner {
        border-style: none;
      }

      .b-widget.b-numberfield.b-bright .b-field-inner::before,
      .b-widget.b-textfield.b-bright .b-field-inner::before {
        background-color: transparent;
      }

      .b-widget.b-numberfield.b-bright,
      .b-widget.b-textfield.b-bright {
        border-color: var(--calenso-black);
        color: var(--calenso-white);

        &:hover {
          border-color: var(--calenso-black);
        }
      }

      .mat-form-field-type-mat-select {
        width: 100%;
      }

      #multiselect-checkbox {
        padding-top: 5px;
      }

      .b-pickerfield {
        width: 100% !important;
        margin: 0 !important;
        border: 0 !important;

        label {
          display: none !important;
        }
      }

      input {
        color: var(--calenso-black);
      }
    }
  }
}

.b-sch-style-colored.b-sch-event-wrap .b-sch-event {
  opacity: 1 !important;
}

.b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected {
  opacity: 1 !important;
}

.b-sch-color-yellow.b-sch-resourcetimerange {
  background-color: rgba(199, 234, 113, 0.2) !important;
  color: #868686 !important;
}

.bryntum-tooltip-info {
  color: var(--calenso-font-color) !important;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: var(--calenso-font-color) !important;
  border-color: transparent;
  color: var(--calenso-white);
}

.calio {
  &-page-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &-page-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--calenso-font-color);
  }

  &-form-label {
    color: var(--calenso-font-color) !important;
    font-size: 14px;
    font-weight: 600;
    display: block;
    padding-top: 8px;
  }

  &-sub-form-label {
    color: var(--calenso-font-color) !important;
    font-size: 14px;
    display: block;
    padding-top: 8px;
  }

  &-divider-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--calenso-font-color);
  }

  &-step-title {
    font-size: 16px !important;
    font-weight: 700;
    color: var(--calenso-font-color);
  }

  &-step-label {
    font-size: 14px;
    font-weight: 700;
    color: var(--calenso-font-color);
  }

  &-step-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--calenso-font-color);
  }
}

//style adjustments bryntum scheduler responsiveLevels small
.b-sch-vertical.b-responsive-small {
  .b-resourceheader-cell {
    .b-resource-name {
      display: none;
    }
  }
}

.rounded-circle {
  border: 1px solid #f0f0f0;
}

.dropdown-menu {
  margin-top: 5px !important;
  border: none !important;
  border-radius: var(--calenso-border-radius) !important;
  -moz-border-radius: var(--calenso-border-radius) !important;
  -webkit-border-radius: var(--calenso-border-radius) !important;
  -webkit-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.5) !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: var(--calenso-white) !important;
  border-color: var(--calenso-font-color) !important;
  background-color: var(--calenso-font-color) !important;
}

.e-multiselect {
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 0 !important;
  }
}

// TODO(Pratik, Kathan): Check all the css with e- mostly those aren't being in use
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  width: 100% !important;
  display: none !important;
}

@media (-moz-touch-enabled: 1),
(pointer: coarse) {
  .e-popup-full-page.e-ddl-device-filter {
    .e-input-group.e-control-wrapper {
      margin-left: 52px !important;
    }
  }
}

.e-datepicker .e-model-header {
  background-color: var(--calenso-violet);
  color: var(--calenso-white);
  cursor: default;
  display: block;
  padding: 10px 10px 10px 15px;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: var(--calenso-violet);
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: var(--calenso-violet);
}

/*! datetimepicker theme */
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: var(--calenso-violet);
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: var(--calenso-violet);
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: var(--calenso-violet);
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: var(--calenso-violet);
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: none;
  border: 1px solid var(--calenso-violet);
  border-radius: 50%;
  color: var(--calenso-violet);
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #eee;
  border: 1px solid var(--calenso-violet);
  color: var(--calenso-violet);
}

.e-calendar .e-content td.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: var(--calenso-violet);
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: 1px solid var(--calenso-violet);
  color: var(--calenso-violet);
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: var(--calenso-violet);
  border: 1px solid var(--calenso-violet);
  box-shadow: inset 0 0 0 2px var(--calenso-white);
  color: var(--calenso-white);
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: var(--calenso-violet);
  color: var(--calenso-white);
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: var(--calenso-violet);
  border: none;
  border-radius: 50%;
  color: var(--calenso-white);
}

.e-calendar .e-content .e-footer,
.e-bigger.e-small .e-calendar .e-content .e-footer {
  color: var(--calenso-violet);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: var(--calenso-violet);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: var(--calenso-violet);
}

.e-btn.e-primary,
.e-css.e-btn.e-primary {
  background-color: var(--calenso-violet);
  border-color: transparent;
  color: var(--calenso-white);
}

.e-btn.e-primary:hover,
.e-css.e-btn.e-primary:hover {
  background-color: var(--calenso-violet);
  border-color: transparent;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: var(--calenso-white);
}

.e-btn.e-primary:focus,
.e-css.e-btn.e-primary:focus {
  background-color: var(--calenso-violet);
  border-color: transparent;
  color: var(--calenso-white);
  outline: #fafafa 0 solid;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.e-btn.e-primary:active,
.e-css.e-btn.e-primary:active {
  background-color: var(--calenso-violet);
  border-color: transparent;
  color: var(--calenso-white);
  outline: #fafafa 0 solid;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-btn.e-primary.e-active,
.e-css.e-btn.e-primary.e-active {
  background-color: var(--calenso-violet);
  border-color: transparent;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: var(--calenso-white);
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: transparent;
  border-color: transparent;
  color: var(--calenso-violet);
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background-color: rgba(227, 22, 91, 0.04);
  border-color: transparent;
  color: var(--calenso-violet);
}

.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus {
  background-color: rgba(227, 22, 91, 0.12);
  border-color: transparent;
  color: var(--calenso-violet);
}

.e-btn.e-flat.e-primary:active,
.e-btn.e-flat.e-primary.e-active,
.e-css.e-btn.e-flat.e-primary:active,
.e-css.e-btn.e-flat.e-primary.e-active {
  background-color: rgba(227, 22, 91, 0.24);
  border-color: transparent;
  color: var(--calenso-violet);
}

.e-btn.e-outline.e-primary,
.e-css.e-btn.e-outline.e-primary {
  background-color: transparent;
  border-color: var(--calenso-violet);
  color: var(--calenso-violet);
}

.e-btn.e-outline.e-primary:hover,
.e-css.e-btn.e-outline.e-primary:hover {
  background-color: var(--calenso-violet);
  border-color: transparent;
  color: var(--calenso-white);
}

.e-btn.e-outline.e-primary:focus,
.e-css.e-btn.e-outline.e-primary:focus {
  background-color: var(--calenso-violet);
  border-color: transparent;
  color: var(--calenso-white);
}

.e-btn.e-outline.e-primary:active,
.e-btn.e-outline.e-primary.e-active,
.e-css.e-btn.e-outline.e-primary:active,
.e-css.e-btn.e-outline.e-primary.e-active {
  background-color: var(--calenso-violet);
  border-color: transparent;
  box-shadow: none;
  color: var(--calenso-white);
}

/*! timepicker theme */
.e-time-wrapper .e-input-group-icon.e-icons.e-active {
  color: var(--calenso-violet);
}

.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: var(--calenso-violet);
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: var(--calenso-violet);
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: var(--calenso-violet);
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus {
  background-color: #e0e0e0;
  border: 1px solid transparent;
  box-shadow: none;
  color: var(--calenso-violet);
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item.e-active.e-item-focus {
  background-color: #e0e0e0;
  border: 1px solid transparent;
  box-shadow: none;
  color: var(--calenso-violet);
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: var(--calenso-violet);
  color: var(--calenso-white);
}

::selection {
  background: var(--calenso-violet);
  /* WebKit/Blink Browsers */
  color: var(--calenso-white);
}

::-moz-selection {
  background: var(--calenso-violet);
  /* Gecko Browsers */
  color: var(--calenso-white);
}

.cdk-overlay-pane {
  @include breakpoint(xxs) {
    overflow: scroll !important;
  }
}

.event-step-1 {
  .e-rte-content {
    margin-top: 30px !important;
    min-height: 120px !important;
  }
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: var(--calenso-font-color);
}

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 100;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  .b-fa-arrow-up {
    padding: 5px;
  }

  button {
    background-color: var(--calenso-white);
    border-radius: var(--calenso-border-radius);
    padding: 5px;
    border: none;
    color: var(--calenso-white);
    background-color: var(--calenso-blueberry);
    height: 40px;
    width: 40px;
  }
}

.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.responsive_table {
  border-radius: var(--calenso-border-radius);
  background: var(--calenso-white);
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 1199px) {
  .responsive_table {
    overflow-x: auto !important;

    .mat-mdc-table {
      min-width: 800px;
    }
  }

  .small_responsive_table {
    overflow-x: auto !important;

    .mat-mdc-table {
      min-width: 450px;
    }
  }
}

.card-header {
  .title {
    color: var(--calenso-font-color) !important;
  }
}

.title-box {
  .title {
    color: var(--calenso-font-color) !important;
  }
}

.field-label {
  color: var(--calenso-font-color) !important;
  font-family: "Open Sans", sans-serif !important;
}

.hex-color-code {
  color: var(--calenso-font-color) !important;
}

.hex-color-label {
  color: var(--calenso-font-color) !important;
}

.worker-name,
.category-name,
.heading {
  color: var(--calenso-font-color) !important;
}

label[class^="input-label-"] {
  color: var(--calenso-font-color) !important;
}

app-appointment-services-detail {
  .card-body {
    color: var(--calenso-font-color) !important;
  }
}

app-custom-fields {
  label {
    color: var(--calenso-font-color) !important;
  }
}

.help-text {
  font-size: 12px !important;
  font-weight: 400 !important;

  a {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}

.calio-tel-input {
  font-size: 14px !important;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  color: var(--calenso-font-color);
  background-color: var(--calenso-white);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: var(--calenso-border-radius);
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.subnav-button {

  .btn-secondary:not(:disabled).active,
  .show>.btn-secondary.dropdown-toggle {
    background-color: var(--calenso-light-sky-blue) !important;
    border-color: var(--calenso-font-color) !important;
    color: var(--calenso-font-color) !important;
  }

  &.bg-invalid {
    color: var(--calenso-badge-danger);
    background-color: var(--calenso-badge-danger-bg);
  }
}


.dropdown-menu {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}


input[type="radio"]:hover {
  appearance: checkbox;
  -webkit-appearance: checkbox;
}

input[type="checkbox"]:hover {
  appearance: checkbox;
  -webkit-appearance: checkbox;
}

.text-muted {
  font-size: 11px !important;
  font-weight: 400 !important;

  a,
  a:visited,
  a:hover {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
}

.small-avatar-choose-employee {
  // height: 35px;
  padding-left: 0px !important;
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option-label {
      a {
        font-size: 13px !important;
      }
    }
  }
}

// full page loader style
.full-page-loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.32);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  .loader-container {
    top: 200px;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .mat-mdc-progress-spinner {
    width: 50px !important;
    height: 50px !important;

    svg {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

@media (max-width: 500px) {
  .search-box-block {
    width: 100% !important;
  }

  .create-button {
    width: 100% !important;
  }
}

.green-background {
  background: var(--calenso-green) !important;
  color: var(--calenso-white);
}

.yellow-background {
  background: var(--calenso-yellow) !important;
  color: var(--calenso-white);
}

.red-background {
  background: var(--calenso-red) !important;
  color: var(--calenso-white);
}

.clock-icon {
  color: rgba(0, 0, 0, 0.54) !important;
  cursor: pointer;
}

.vulnerable-error-msg {
  color: var(--calenso-red) !important;
}

.calenso-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1px;
}

.primary-dot {
  background-color: var(--calenso-font-color);
}

.secondary-dot {
  background-color: var(--calenso-gray);
}

.success-dot {
  background-color: var(--calenso-green);
}

.danger-dot {
  background-color: var(--calenso-red);
}

.warning-dot {
  background-color: var(--calenso-yellow);
}

.info-dot {
  background-color: var(--calenso-sky-blue);
}

.light-dot {
  background-color: var(--calenso-light);
}

.dark-dot {
  background-color: var(--calenso-font-color);
}

// Badge css
.bg-primary {
  color: var(--calenso-white);
  background-color: var(--calenso-font-color) !important;
}

.bg-danger {
  color: var(--calenso-white);
  background-color: var(--calenso-red) !important;
}

.bg-success {
  background-color: var(--calenso-green) !important;
  color: var(--calenso-white);
  letter-spacing: 0.3px;
}

.bg-violett {
  color: var(--calenso-white);
  background-color: var(--calenso-violet) !important;
}

.bg-orange {
  color: var(--calenso-white);
  background-color: var(--calenso-yellow) !important;
}

.bg-yellow {
  color: var(--calenso-white);
  background-color: var(--calenso-yellow) !important;
}

unblu>div:first-child {
  bottom: 16px !important;
}

.cal-company-link {
  color: var(--calenso-violet) !important;
}

.cal-dt {
  margin-top: 10px !important;
}

.mdc-switch__track,
.text-muted,
p,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
small,
button,
.form-control {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

// experiment css
.calio-global-search-highlighter {
  background-color: #ffcb3d33;
  border-radius: var(--calenso-border-radius);
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 p 0px 64px -24px rgba(255, 203, 61, 0.2) !important;
}

.calio-stepper-title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
}

.calio-stepper-subtitle {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 20%;
  margin-right: 20%;
}

.hex-color-code {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.stick-to-bottom {
  border-top: 1px solid var(--bs-border-color);
  position: sticky;
  bottom: 0;
  background-color: var(--calenso-white);
  overflow-y: hidden;
  padding-bottom: 15px;
  padding-top: 15px;
  z-index: 999;
  margin: 0;
}

/* Custom css for date range picker  */
.cutom-website-datepicker {
  .mat-date-range-input-container {
    height: 35px;
  }

  .mat-mdc-input-element {
    caret-color: var(--calenso-black) !important;
  }

  .mat-date-range-input-inner[disabled] {
    color: var(--calenso-black);
  }

  .mat-date-range-input-separator {
    display: none;
  }

  .mat-date-range-input-hide-placeholders .mat-date-range-input-separator {
    display: block;
  }

  &.is-invalid .mdc-text-field--invalid {
    border-color: var(--calenso-red) !important;
  }

  .mat-datepicker-toggle {
    .mdc-icon-button.mat-mdc-button-base {
      font-size: 20px !important;
    }
  }
}

mat-form-field {
  input {
    font-size: 14px !important;
  }

  .mdc-text-field--filled {
    &:not(.mdc-text-field--disabled) {
      background-color: var(--calenso-white);
    }
  }

  mat-date-range-input {
    font-size: 14px !important;
  }
}

mat-datepicker-toggle {
  &.mat-datepicker-toggle {
    line-height: normal;

    svg {
      height: 18px;
      width: 18px;
      font-size: 20px !important;
    }
  }
}

mat-form-field {
  input {
    font-size: 14px !important;
  }

  .mdc-text-field--filled {
    &:not(.mdc-text-field--disabled) {
      background-color: var(--calenso-white);
    }
  }

  mat-date-range-input {
    font-size: 14px !important;
  }
}

mat-datepicker-toggle {
  &.mat-datepicker-toggle {
    line-height: normal;

    svg {
      height: 18px;
      width: 18px;
      display: block;
    }
  }
}

/* Custom css for date range picker  */

/* css for remove number input type arrows  */
/* Chrome, Safari, Edge, Opera */
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].no-arrows {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* css for remove number input type arrows  */

// Overwrite bootstrap color - Start
.text-success {
  color: var(--calenso-green) !important;
}

.text-danger {
  color: var(--calenso-red) !important;
}

.text-primary {
  color: var(--calenso-font-color) !important;
}

.text-black {
  color: var(--calenso-black) !important;
}

.text-warning {
  color: var(--calenso-yellow) !important;
}

.text-gray {
  color: var(--calenso-gray) !important;
}

.text-blueberry {
  color: var(--calenso-blueberry) !important;
}

// Overwrite bootstrap color - End

.invalid-feedback {
  color: var(--calenso-red);
}

.ng-select-disabled {
  .ng-select-container {
    background-color: var(--calenso-light-gray) !important;
  }
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px;
}

.lh-normal {
  line-height: normal;
}

.calenso-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  width: fit-content;
  height: fit-content;

  &.calenso-bg-warning {
    background-color: var(--calenso-badge-warning-bg);
    color: var(--calenso-badge-warning);
  }

  &.calenso-bg-success {
    background-color: var(--calenso-badge-success-bg);
    color: var(--calenso-badge-success);
  }

  &.calenso-bg-danger {
    background-color: var(--calenso-badge-danger-bg);
    color: var(--calenso-badge-danger);
  }

  &.calenso-bg-info {
    background-color: var(--calenso-badge-info-bg);
    color: var(--calenso-badge-info);
  }
}

// angular-editor styles start
.angular-editor {
  .angular-editor-textarea {
    margin-top: 0 !important;
    border-top: none !important;
  }

  .angular-editor-button {
    float: unset !important;
  }
}

// angular-editor styles end

// Style for worker selection in rebooking appointment on customer landing page start
.rebooking-wroker-selection {
  .worker {
    display: flex;
    gap: 10px;
    align-items: center;
    text-wrap: wrap;

    .avatar-wrapper {
      min-width: 35px;

      .avatar {
        height: 35px;
        width: 35px;
        border-radius: 50%;
      }
    }

    .worker-name {
      font-weight: 700;
    }

    .worker-function {
      margin-top: 2px;
      font-size: 12px;
    }
  }
}

.wrap-text-dropdown {
  &.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        white-space: normal;
        overflow: visible;
      }
    }
  }
}

// end

// Custom width
.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.min-w-90 {
  min-width: 90%;
}

//end

.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0;
}

#launcher-frame {
  width: 100%;
  max-width: 100% !important;
  left: 0 !important;
  right: 20px;
  bottom: 0 !important;
  position: absolute !important;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.4));
}

.new-theme {
  #launcher-frame {
    display: none;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  #launcher-frame {
    display: none;
    padding: 0;
    margin: 0;
  }
}

#freshworks-container {
  position: fixed !important;
  min-width: 104px !important;
}

#freshworks-frame-wrapper.frame-left {
  right: 20px;
  left: auto !important;
}

.border {
  border: 1px solid var(--calenso-light-gray-shade-2);
  border-radius: var(--calenso-border-radius);
}

.new-theme {
  background-color: var(--calenso-white);

  .card {
    --bs-card-border-width: 1px;
    border: var(--bs-card-border-width) solid var(--bs-border-color) !important;

    &.border-none {
      border: none !important;
    }
  }

  .shadow {
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;
  }
}

.old-theme {
  background-color: var(--calenso-light-sky-blue);

  .card {
    border: none !important;
  }

  .shadow {
    -webkit-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 0px 64px -26px rgba(0, 0, 0, 0.2) !important;
  }

  .small-avatar-choose-employee {
    .ng-select .ng-select-container {
      border: none !important;
    }
  }

  .mat-drawer-container {
    overflow: inherit !important;
    color: var(--calenso-font-color) !important;
  }

  .mat-drawer-content {
    background: var(--calenso-light-sky-blue) !important;
    overflow: inherit !important;
  }

  .waiting-time-picker {
    .mat-mdc-form-field .mdc-text-field--filled {
      border: none !important;
    }
  }

  @media (max-width: 991px) {
    .calio-body {
      margin-top: 0px !important;
    }
  }

  @media (min-width: 992px) {
    .calio-body {
      margin-top: 140px;
    }
  }
}

.border-none {
  border: none !important;
}

.integration-guideline {
  a {
    text-decoration: underline !important;
  }
}

.flex-auto {
  flex: auto;
}

// Custom scroll bar start
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--calenso-light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--calenso-gray);
  border-radius: 10px;
  cursor: default;
}

// Custom scroll bar end

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

.overflow-auto {
  overflow: auto;
}

.mt-3px {
  margin-top: 3px;
}

.ml-auto {
  margin-left: auto;
}

.leading-normal {
  line-height: normal;
}

.calio-truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Adds '...' at the end */
}

.line-2 {
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /* Limits text to 2 lines */
}


.toggle-label {
  color: var(--calenso-font-color);
  font-weight: 600;
}

.filter-box ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 4px 8px !important;

  .ng-option-label {
    font-size: 14px !important;
  }
}