.btn-primary {
  font-size: 14px !important;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-primary-color) !important;
  border-color: var(--calenso-primary-color) !important;

  &:hover:not(.no-hover) {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-primary-color) !important;
    border-color: var(--calenso-primary-color) !important;
  }

  &:disabled {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-primary-color) !important;
    border-color: var(--calenso-primary-color) !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.no-pointer {
    cursor: auto !important;
  }
}

.btn-outline-primary {
  font-size: 14px !important;
  color: var(--calenso-font-color) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-primary-color) !important;

  &:hover:not(.no-hover) {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-primary-color) !important;
  }

  &:disabled {
    cursor: not-allowed !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.no-pointer {
    cursor: auto !important;
  }
}

.btn-warn {
  font-size: 14px !important;
  color: var(--calenso-black) !important;
  background-color: var(--calenso-orange) !important;
  border-color: var(--calenso-orange) !important;

  &:hover:not(.no-hover) {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-orange) !important;
    border-color: var(--calenso-orange) !important;
  }

  &:disabled {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-orange) !important;
    border-color: var(--calenso-orange) !important;
  }
}

.btn-danger {
  font-size: 14px !important;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-red) !important;
  border-color: var(--calenso-red) !important;

  &:hover:not(.no-hover) {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-red) !important;
    border-color: var(--calenso-red) !important;
  }

  &:disabled {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-red) !important;
    border-color: var(--calenso-red) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn-success {
  font-size: 14px !important;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-green) !important;
  border-color: var(--calenso-green) !important;

  &:hover:not(.no-hover) {
    background-color: var(--calenso-green) !important;
    border-color: var(--calenso-green) !important;
    color: var(--calenso-white) !important;
  }

  &:disabled {
    background-color: var(--calenso-green) !important;
    border-color: var(--calenso-green) !important;
    color: var(--calenso-white) !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.no-pointer {
    cursor: auto !important;
  }
}

.btn-outline-success {
  font-size: 14px !important;
  color: var(--calenso-green) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-green) !important;

  &:hover:not(.no-hover) {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-green) !important;
  }

  &:disabled {
    cursor: not-allowed !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.no-pointer {
    cursor: auto !important;
  }
}

.btn-secondary {
  font-size: 14px !important;
  color: var(--calenso-font-color) !important;
  background-color: var(--calenso-hawkes-blue) !important;
  border-color: var(--calenso-hawkes-blue) !important;

  &:hover:not(.no-hover, .btn-group, .btn-secondary) {
    color: var(--calenso-font-color) !important;
    background-color: var(--calenso-hawkes-blue) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }

  &:disabled {
    color: var(--calenso-font-color) !important;
    background-color: var(--calenso-hawkes-blue) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn-outline-light {
  font-size: 14px !important;
  color: var(--calenso-black) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-hawkes-blue) !important;

  &:hover:not(.no-hover) {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-white) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }

  &:disabled {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-white) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }
}

.btn-violet {
  font-size: 14px !important;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-violet) !important;
  border-color: var(--calenso-violet) !important;

  &:hover:not(.no-hover) {
    opacity: 0.85;
    transition: .2s;
    color: var(--calenso-white) !important;
    background-color: var(--calenso-violet) !important;
    border-color: var(--calenso-violet) !important;
  }

  &:disabled {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-violet) !important;
    border-color: var(--calenso-violet) !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.no-pointer {
    cursor: auto !important;
  }
}

.btn-outline-violet {
  font-size: 14px !important;
  color: var(--calenso-violet) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-violet) !important;

  &:hover:not(.no-hover) {
    transition: .2s;
    color: var(--calenso-white) !important;
    background-color: var(--calenso-violet) !important;
  }

  &:disabled {
    cursor: not-allowed !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.no-pointer {
    cursor: auto !important;
  }
}

.btn-xs {
  font-size: 13px !important;
}

.mark-all-read-icon {
  color: var(--calenso-font-color);
  font-size: 20px;
}

.btn-group {
  .subnav-button {
    background-color: var(--calenso-white) !important;
    border-color: var(--calenso-primary-color) !important;
    color: var(--calenso-font-color) !important;

    &.bg-invalid {
      color: var(--calenso-badge-danger) !important;
      background-color: var(--calenso-badge-danger-bg) !important;
    }

    &:active,
    &:hover {
      background-color: var(--calenso-light-sky-blue) !important;
      border-color: var(--calenso-primary-color) !important;
      color: var(--calenso-font-color) !important;
    }
  }

  .selected-subnav-button {
    background-color: var(--calenso-light-sky-blue) !important;
  }
}
